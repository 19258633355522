import React from 'react'

import Paragraph from 'components/Paragraph'
import Border from 'components/Border'

import DownloadIcon from 'images/icons/download.svg'
import FacebookIcon from 'images/icons/facebook.svg'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="paragraph-container">
          <Border />
          <Paragraph text="Interested in attending a Tony Robbins event and experiencing these results for yourself?" />
        </div>
        <a
          href="https://www.tonyrobbins.com/events/"
          className="link-button"
          target="_blank"
          rel="noreferrer noopener"
        >
          View All Events
          <DownloadIcon />
        </a>
      </div>
      <div className="footer-bottom">
        <Paragraph
          text={`© Copyright ${new Date().getFullYear()} Tony Robbins. All rights reserved.`}
        />
        <div className="footer-icons">
          <a
            href="https://www.instagram.com/TonyRobbins"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Instagram Account - @TonyRobbins"
          >
            <span className="icon">
              <FacebookIcon />
            </span>
            @TonyRobbins
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
