import React, { useState } from 'react'
import cx from 'classnames'
import InView from 'react-intersection-observer'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const data = [
  { name: '1', value: 2 },
  { name: '2', value: 2 },
  { name: '3', value: 6 },
]

const COLORS = [
  { start: 'rgba(57, 108, 213, 1)', end: 'rgba(57, 108, 213, 1)' },
  { start: 'rgba(41, 79, 155, 1)', end: 'rgba(41, 79, 155, 1)' },
  { start: 'rgba(57, 108, 213, .75)', end: 'rgba(57, 108, 213, .75)' },
]

const StudySectionThree = () => {
  const breakpoints = useBreakpoint()
  const [isChartActive, setChartActive] = useState(false)

  function onShowChart(isVisible) {
    if (!breakpoints.m) {
      setChartActive(isVisible)
    }
  }

  /* eslint-disable react/no-array-index-key */
  const renderChart = () => (
    <div className={cx('section-chart', { 'is-active': isChartActive })}>
      <div className="chart">
        <ResponsiveContainer width="99%" aspect={1}>
          <PieChart>
            <defs>
              {data.map((entry, index) => (
                <linearGradient
                  key={`chart-1-${index}`}
                  id={`chart-1-${index}`}
                >
                  <stop
                    offset="0%"
                    stopColor={COLORS[index % COLORS.length].start}
                  />
                  <stop
                    offset="100%"
                    stopColor={COLORS[index % COLORS.length].end}
                  />
                </linearGradient>
              ))}
            </defs>
            <Pie
              startAngle={-55}
              data={data}
              dataKey="value"
              innerRadius="50%"
              isAnimationActive={isChartActive}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#chart-1-${index})`} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="chart-container">
          <h2>40%</h2>
          <p>
            (Of which only <b>50%</b> show actual improvement)
          </p>
        </div>
        <div className="svg-border">
          <svg
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle-dashed"
              cx="100"
              cy="100"
              r="96"
              stroke="#FFFCF3"
              strokeWidth="2"
              fillOpacity="0"
            />
          </svg>
        </div>
      </div>
    </div>
  )
  /* eslint-enable react/no-array-index-key */

  return (
    <div className="study-sub-section --with-stats">
      {renderChart()}
      <div className="study-stats blue">
        <InView threshold="0.6" initialInView onChange={onShowChart}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={cx('study-stats-container', { 'fade-in': inView })}
            >
              <h3>Drug Therapy</h3>
              <div className="study-sub-heading">
                <p>
                  Only <span>40%</span> respond to drug treatments for anxiety
                  and depression.
                </p>
              </div>
              <div className="study-details">
                <p>
                  According to metastudies (studies that combine and analyze all
                  discoverable results), only about 40% of people respond to
                  treatments for anxiety and depression, and of those that do,
                  they only show an average of 50% improvement in their
                  symptoms. Some studies have even shown response rate to be as
                  little as 33%.*
                </p>

                <cite>
                  *{' '}
                  <u>
                    Rush AJ, Warden D, Wisniewski SR, et al. STAR*D: revising
                    conventional wisdom. CNS Drugs. 2009.
                  </u>
                </cite>
                <cite>
                  *{' '}
                  <u>
                    Kudlow PA, McIntyre RS, Lam RW. Early switching strategies
                    in antidepressant non-responders: current evidence and
                    future research directions. CNS Drugs. 2014.
                  </u>
                </cite>
              </div>
            </div>
          )}
        </InView>
      </div>
    </div>
  )
}

export default StudySectionThree
