import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import TonyRobbinsLogo from 'images/tony-robbins-logo.svg'

const Intro = ({ isVideoReady }) => {
  return (
    <div className={cx('intro', { 'is-ready': isVideoReady })}>
      <div className="intro-container">
        <TonyRobbinsLogo />
        <div className="intro-border">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  )
}

Intro.propTypes = {
  isVideoReady: PropTypes.bool,
}

export default Intro
