import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isEmpty } from 'lodash'

import Border from 'components/Border'
import VideoPlayer from 'components/VideoPlayer'
import PlayButtonIcon from 'images/icons/play-button.svg'

const Hero = ({ onVideoReady, onViewChange, onPlay }) => {
  const heroRef = useRef(null)

  useEffect(() => {
    if (!isEmpty(heroRef.current)) {
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.create({
        onEnter: () => onViewChange(0),
        onEnterBack: () => onViewChange(0),
        trigger: heroRef.current,
        start: 'bottom bottom',
        end: 'bottom center',
        // markers: { startColor: 'blue', endColor: 'blue' },
      })
    }
  }, [heroRef.current])

  return (
    <header className="hero" ref={heroRef}>
      <div className="hero-background">
        <VideoPlayer onVideoReady={onVideoReady} />
        <button
          type="button"
          onClick={onPlay}
          className="play-button"
          aria-label="Play"
        >
          <span className="border">
            <svg
              viewBox="0 0 200 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="circle"
                cx="100"
                cy="100"
                r="96"
                stroke="#FFFCF3"
                strokeWidth="2"
                fillOpacity="0"
              />
              <circle
                className="circle-dashed"
                cx="100"
                cy="100"
                r="96"
                stroke="#FFFCF3"
                strokeWidth="2"
                fillOpacity="0"
              />
            </svg>
          </span>
          <span className="play">
            <PlayButtonIcon />
          </span>
        </button>
      </div>
      <div className="hero-container">
        <div className="hero-heading">
          <Border />
          <div>
            <h3 className="sub-heading">
              Scientific Study on Tony Robbins Events By
            </h3>
            <h1>Snyder Lab of Genetics</h1>
            <h2>at Stanford University</h2>
          </div>
        </div>
      </div>
    </header>
  )
}

Hero.propTypes = {
  onVideoReady: PropTypes.func,
  onViewChange: PropTypes.func,
  onPlay: PropTypes.func,
}

export default Hero
