import React, { useState, useCallback, useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import cx from 'classnames'

import SEO from 'components/SEO'
import Intro from 'components/Intro'
import Hero from 'components/Hero'
import SectionStudy from 'components/SectionStudy'
import SectionResults from 'components/SectionResults'
import SectionProfilees from 'components/SectionProfilees'
import SectionResearchers from 'components/SectionResearchers'
import SectionFurtherContent from 'components/SectionFurtherContent'
import Footer from 'components/Footer'
import Sidebar from 'components/Sidebar'
import StickyHeader from 'components/StickyHeader'
import TopIcon from 'images/icons/top.svg'
// import video from 'video/hero.mp4'
import 'styles/main.scss'

gsap.registerPlugin(ScrollToPlugin)

function IndexPage() {
  const pageRef = useRef(null)
  const heroRef = useRef(null)
  const studyRef = useRef(null)
  const resultsRef = useRef(null)
  const videoRef = useRef(null)
  const participantsRef = useRef(null)
  const researchRef = useRef(null)

  const [isScrollActive, setScrollActive] = useState(false)
  const [hasPressedPlay, setPressedPlay] = useState(false)
  const [isVideoReady, setVideoReady] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const refMap = {
    hero: heroRef,
    study: studyRef,
    results: resultsRef,
    participants: participantsRef,
    research: researchRef,
  }

  useEffect(() => {
    window.addEventListener('resize', onResize, false)
    window.addEventListener('scroll', onScroll, false)

    return () => {
      window.removeEventListener('resize', onResize, false)
    }
  }, [])

  useEffect(() => {
    if (hasPressedPlay) {
      setTimeout(() => {
        if (videoRef.current.requestFullscreen) {
          videoRef.current.addEventListener(
            'fullscreenchange',
            onFullscreenChange,
            false,
          )
        }
        if (videoRef.current.fullscreenEnabled) {
          videoRef.current.addEventListener(
            'webkitendfullscreen',
            onFullscreenChange,
            false,
          )
        }
      }, 100)
    }
  }, [hasPressedPlay])

  const onViewChange = useCallback(
    (index) => {
      ScrollTrigger.refresh()
      setCurrentIndex(index)
    },
    [setCurrentIndex],
  )
  const onPlay = useCallback(() => {
    setPressedPlay(true)
    videoRef.current.play()
    onToggleFullscreen()
  }, [setPressedPlay])

  const onVideoReady = useCallback(() => {
    setTimeout(() => {
      setVideoReady(true)
    }, 2000)
  }, [setVideoReady])

  const onScrollToSection = useCallback((section) => {
    ScrollTrigger.refresh()
    const timer = setTimeout(() => {
      if (section === 'study') {
        const sectionTop =
          refMap[section].current.offsetTop + window.innerHeight / 2.5
        gsap.to(window, { duration: 1, scrollTo: { y: sectionTop } })
      } else if (section === 'results') {
        const sectionTop =
          refMap[section].current.offsetTop + window.innerHeight * 0.3
        gsap.to(window, { duration: 1, scrollTo: { y: sectionTop } })
      } else {
        const sectionTop = refMap[section].current.offsetTop
        gsap.to(window, { duration: 1, scrollTo: { y: sectionTop } })
      }
      clearTimeout(timer)
    }, 10)
  }, [])

  function onStop() {
    setPressedPlay(false)
  }

  function onFullscreenChange() {
    if (!document.fullscreenElement) {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
      onStop()
    }
  }

  function onToggleFullscreen() {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen()
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen()
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen()
    }
  }

  function onResize() {
    ScrollTrigger.refresh()
  }

  function onScroll() {
    if (window.scrollY > 300 && !isScrollActive) {
      setScrollActive(true)
    } else {
      setScrollActive(false)
    }
  }

  function onScrollTop() {
    gsap.to(window, { duration: 1, scrollTo: { y: 0 } })
  }
  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <div className="container">
      <SEO />
      <div className="content" hidden={!isVideoReady}>
        <span className="top-border"></span>
        <StickyHeader index={currentIndex} />
        <div className="page-container">
          <main className="page-content">
            <div className="page-body" ref={pageRef}>
              <div ref={heroRef}>
                <Hero
                  onVideoReady={onVideoReady}
                  onViewChange={onViewChange}
                  onPlay={onPlay}
                />
              </div>
              {isVideoReady && (
                <>
                  <div ref={studyRef}>
                    <SectionStudy onViewChange={onViewChange} />
                  </div>
                  <div ref={resultsRef}>
                    <SectionResults onViewChange={onViewChange} />
                    <div className="section-wrapper">
                      <SectionFurtherContent />
                    </div>
                  </div>
                  <div ref={participantsRef}>
                    <SectionProfilees onViewChange={onViewChange} />
                  </div>
                  <div ref={researchRef}>
                    <SectionResearchers onViewChange={onViewChange} />
                  </div>
                </>
              )}
            </div>
            <Footer />
            <video
              ref={videoRef}
              width="100%"
              controls
              className="video-player"
              preload="metadata"
              hidden={!hasPressedPlay}
            >
              {/* <source src={video} type="video/mp4" /> */}
              <source
                src="https://cdnwp.tonyrobbins.com/video/Stanford_Study_Short_V14_1-web.mp4"
                type="video/mp4"
              />
            </video>
          </main>
        </div>
        <div
          className={cx('sidebar-line', { 'is-active': isScrollActive })}
        ></div>
        <Sidebar
          index={currentIndex}
          pageRef={pageRef}
          researchRef={researchRef}
          onScrollToSection={onScrollToSection}
          onViewChange={onViewChange}
        />
        <button
          type="button"
          onClick={onScrollTop}
          className="top-button"
          aria-label="Scroll back to top button"
        >
          <TopIcon />
        </button>
      </div>
      <Intro isVideoReady={isVideoReady} />
    </div>
  )
  /* eslint-enable jsx-a11y/media-has-caption */
}

export default IndexPage
