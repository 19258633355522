import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const Border = ({ isHorizontal, isRed }) => {
  return (
    <div
      className={cx('heading-border', {
        'is-horizontal': isHorizontal,
        'is-red': isRed,
      })}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

Border.propTypes = {
  isHorizontal: PropTypes.bool,
  isRed: PropTypes.bool,
}

export default Border
