import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import HeroImage from 'images/backgrounds/hero.png'

const VideoPlayer = ({ onVideoReady }) => {
  const loopVideoRef = useRef(null)

  useEffect(() => {
    if (!isEmpty(loopVideoRef.current)) {
      loopVideoRef.current.load()
      loopVideoRef.current.addEventListener(
        'canplaythrough',
        onVideoLoad,
        false,
      )
    }
  }, [])

  function onVideoLoad() {
    if (
      !isEmpty(loopVideoRef.current) &&
      loopVideoRef.current.readyState >= 3
    ) {
      onVideoReady()
      loopVideoRef.current.removeEventListener(
        'canplaythrough',
        onVideoLoad,
        false,
      )
    }
  }

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <div className="video-container">
      <div className="video-poster">
        <video
          ref={loopVideoRef}
          poster={HeroImage}
          preload="auto"
          playsInline
          controls={false}
          muted
          autoPlay
          loop
        >
          <source
            src="https://cdnwp.tonyrobbins.com/video/hero-loop-mobile.mp4"
            type="video/mp4"
            media="all and (max-width:480px)"
          />
          <source
            src="https://cdnwp.tonyrobbins.com/video/hero-loop.mp4"
            type="video/mp4"
            media="all and (min-width:481px)"
          />
        </video>
      </div>
    </div>
  )
  /* eslint-enable jsx-a11y/media-has-caption */
}

VideoPlayer.propTypes = {
  onVideoReady: PropTypes.func,
}

export default VideoPlayer
