import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isEmpty } from 'lodash'

import SectionHeading from 'components/SectionHeading'
import Researcher from 'components/Researcher'
// import Paragraph from 'components/Paragraph'

import ImageOne from 'images/profiles/image-one.png'
import ImageTwo from 'images/profiles/image-two.png'
import ImageThree from 'images/profiles/image-three.png'
import ImageFour from 'images/profiles/image-four.png'
import ImageOneMobile from 'images/profiles/image-one-mobile.jpg'
import ImageTwoMobile from 'images/profiles/image-two-mobile.jpg'
import ImageThreeMobile from 'images/profiles/image-three-mobile.jpg'
import ImageFourMobile from 'images/profiles/image-four-mobile.jpg'
import StanfordLogo from 'images/stanford-university-logo.svg'

function SectionResearchers({ onViewChange }) {
  const researcherRef = useRef(null)

  useEffect(() => {
    if (!isEmpty(researcherRef.current)) {
      setTimeout(() => {
        ScrollTrigger.create({
          onEnter: () => onViewChange(4),
          onEnterBack: () => onViewChange(4),
          trigger: researcherRef.current,
          // markers: { startColor: 'white', endColor: 'white' },
          start: 'top center',
          end: `bottom +=${window.innerHeight}`,
        })
      }, 4000)
    }
  }, [researcherRef.current])

  return (
    <section className="section --researchers" ref={researcherRef}>
      <div className="section-wrapper">
        <div className="section-header">
          <SectionHeading top="Meet the" bottom="researchers" />
          {/* <Paragraph text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed accumsan felis. Vivamus sed lorem est. Praesent eu congue libero. Aenean metus magna, feugiat nec condimentum a, gravida eget diam. Morbi a dolor rutrum, bibendum ante quis, ultrices dui. Sed quis ligula nulla. Donec pretium molestie ex. Aenean ut magna ex. " /> */}
        </div>
        <div className="section-body --researchers">
          <Researcher
            image={ImageOne}
            imageMobile={ImageOneMobile}
            name="Michael Snyder"
            job="PhD, FACS, Professor of Genetics"
            logo={<StanfordLogo />}
            text="Dr. Snyder received his Ph.D training at the California Institute of Technology and carried out postdoctoral training at Stanford University. He is a leader in the field of functional genomics and proteomics, and one of the major participants of the ENCODE project."
          />
          <Researcher
            image={ImageTwo}
            imageMobile={ImageTwoMobile}
            name="ariel ganz"
            job="Ph.D"
            logo={<StanfordLogo />}
            text="Ariel Bacaner Ganz is a postdoctoral fellow at Stanford University in the Snyder Lab, Department of Genetics in the School of Medicine researching effective strategies for happiness and well-being, and how psychological changes can alter health on a molecular level. She holds a doctoral degree from Cornell University and has published across diverse research fields including mental health, nutrition, and precision medicine."
          />
          <Researcher
            image={ImageThree}
            imageMobile={ImageThreeMobile}
            name="Benjamin Rolnik"
            job="Director of Stanford’s Healthcare Innovation Lab"
            logo={<StanfordLogo />}
            text="As the Director of Stanford’s Healthcare Innovation Lab (SHIL), Ben and the SHIL team are bridging the gap between technology and medicine on a mission to transform healthcare. Their goal is to make personalised, precision healthcare an affordable reality for all."
          />
          <Researcher
            image={ImageFour}
            imageMobile={ImageFourMobile}
            name="Dr. Jacob wilson"
            job="Ph.D"
            text="Dr. Jacob Wilson is a researcher and CEO of Applied Science and Performance Institute. With over 250 peer- reviewed papers, book chapters and abstracts. Dr. Wilson has focused his research on the cellular, molecular, and whole body changes which impact health and human longevity.						"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tony-robbins-stanford-study.appspot.com/o/aspi-logo.png?alt=media&token=19a2ae1d-e65a-43b4-a320-b670c19a60cc"
              alt="ASPI Labs"
            />
          </Researcher>
        </div>
      </div>
    </section>
  )
}

SectionResearchers.propTypes = {
  onViewChange: PropTypes.func,
}

export default SectionResearchers
