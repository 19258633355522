import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Border from 'components/Border'

const SectionHeading = ({ top, bottom, isHero, bold, isSmall }) => {
  return (
    <div className={cx('section-heading', { 'is-small': isSmall })}>
      {isHero ? (
        <>
          <h1 className="heading-top">
            <Border />
            {top}
          </h1>
          <h1 className="heading-bottom">
            <b>{bold}</b>
            {bottom}
          </h1>
        </>
      ) : (
        <>
          <h2 className="heading-top">
            <Border />
            {top}
          </h2>
          <h2 className="heading-bottom">{bottom}</h2>
        </>
      )}
    </div>
  )
}

SectionHeading.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  bold: PropTypes.string,
  isHero: PropTypes.bool,
  isSmall: PropTypes.bool,
}

export default SectionHeading
