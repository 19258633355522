import React from 'react'

import SectionHeading from 'components/SectionHeading'
import Paragraph from 'components/Paragraph'

import PlayButtonIcon from 'images/icons/play-button.svg'
import DownloadIcon from 'images/icons/download.svg'
import ImageOne from 'images/backgrounds/watch.png'

const SectionFurtherContent = () => {
  return (
    <section className="section --further">
      <div className="section-header">
        <SectionHeading top="Take a" bottom="Deeper Dive" />
        <div className="section-body">
          <div className="section-row">
            <div className="section-video-container">
              <a
                href="https://youtu.be/rpDCoW9U4zM"
                target="_blank"
                rel="noreferrer"
                aria-label="Watch the full study"
              >
                <PlayButtonIcon />
                <span>Watch the full study</span>
              </a>
              <img
                width="100%"
                height="100%"
                src={ImageOne}
                alt="Watch the full study"
              />
            </div>
          </div>

          <div className="section-row --apart">
            <div className="section-content">
              <Paragraph text="Read the full scientific studies on Unleash the Power Within and Date with Destiny" />
              <div className="download-container">
                <a
                  href="https://www.sciencedirect.com/science/article/pii/S0031938421001530"
                  rel="noreferrer noopener"
                  className="link-button --download"
                  target="_blank"
                >
                  Download UPW Study
                  <DownloadIcon />
                </a>
                <a
                  href="https://www.sciencedirect.com/science/article/pii/S0022395622001157"
                  rel="noreferrer noopener"
                  className="link-button --download"
                  target="_blank"
                >
                  Download DWD Study
                  <DownloadIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFurtherContent
