import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isEmpty } from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { InView } from 'react-intersection-observer'
import cx from 'classnames'

import Border from 'components/Border'
import SectionResult from 'components/SectionResult'
import StatOneOne from 'images/stats/stat-one-one.svg'
import StatOneTwo from 'images/stats/stat-one-two.svg'
import StatTwoOne from 'images/stats/stat-two-one.svg'
import StatTwoTwo from 'images/stats/stat-two-two.svg'
import BackgroundOne from 'images/backgrounds/study-2.jpg'
import BackgroundOneMobile from 'images/backgrounds/study-2-mobile.jpg'
import BackgroundTwo from 'images/backgrounds/study-3.jpg'
import BackgroundTwoMobile from 'images/backgrounds/study-3-mobile.jpg'
import BackgroundThree from 'images/backgrounds/study-4.jpg'
import BackgroundThreeMobile from 'images/backgrounds/study-4-mobile.jpg'
import ResultThree from './ResultThree'

const SectionResults = ({ onViewChange }) => {
  const resultsRef = useRef(null)
  const breakpoints = useBreakpoint()

  useEffect(() => {
    if (!isEmpty(resultsRef.current)) {
      ScrollTrigger.create({
        onEnter: () => onViewChange(2),
        onEnterBack: () => onViewChange(2),
        trigger: resultsRef.current,
        start: 'top center',
        end: `+=${resultsRef.current.offsetHeight}`,
        // markers: { startColor: 'pink', endColor: 'pink' },
      })
    }
  }, [resultsRef.current])

  function renderResultOne() {
    return (
      <div className="section-header">
        {!breakpoints.m && (
          <div className="section-subheading">
            <Border />
            <div>
              <h2 className="heading-top ">Breakthrough</h2>
              <h2 className="heading-bottom">Finding #2</h2>
            </div>
          </div>
        )}
        <p>
          Study participants report a <span>+50%</span> increase in positive
          emotions & a <span>-70%</span> decrease in .
        </p>
        <div className="results-row">
          <InView className="results-stat-view">
            {({ inView, ref }) => (
              <div
                className={cx('results-stat', { 'is-active': inView })}
                ref={ref}
              >
                <div className="stat --one-one">
                  <StatOneOne />
                </div>
                <Border isHorizontal />
                <div className="stat --one-two">
                  <StatOneTwo />
                </div>
              </div>
            )}
          </InView>
          <p>
            One month after their event, study participants still experienced a{' '}
            <b>+50%</b> increase in positive emotions like life satisfaction,
            inner strength and well-being, while also reporting a <b>-70%</b>{' '}
            decrease in negative emotions like loneliness, sadness, anger,
            stress and anxiety.{' '}
            <b>
              In addition, they report feeling happier and more satisfied in
              their relationships.
            </b>
          </p>
        </div>
      </div>
    )
  }

  function renderResultTwo() {
    return (
      <div className="section-header">
        {!breakpoints.m && (
          <div className="section-subheading">
            <Border />
            <div>
              <h2 className="heading-top ">Breakthrough</h2>
              <h2 className="heading-bottom">Finding #3</h2>
            </div>
          </div>
        )}
        <p>
          Study participants saw a <span>38%</span> decrease in anxiety during
          the pandemic, even 11 months later, while the baseline showed a{' '}
          <span>28%</span> increase.
        </p>
        <div className="results-row">
          <InView className="results-stat-view">
            {({ inView, ref }) => (
              <div
                className={cx('results-stat --two', { 'is-active': inView })}
                ref={ref}
              >
                <div className="stat --two-one">
                  <StatTwoOne />
                </div>
                <div className="stat --two-two">
                  <StatTwoTwo />
                </div>
              </div>
            )}
          </InView>
          <p>
            These findings are not outliers and they are not fair-weather
            statistics. This study was conducted over the course of the COVID-19
            pandemic – a time when, among the general population, loneliness
            increased, depression increased, and life satisfaction decreased.{' '}
            <b>
              But these results show that the skills participants master at
              Unleash the Power Within help them navigate even the toughest,
              most challenging times in their lives with greater emotional
              fitness and resiliency.
            </b>
          </p>
        </div>
      </div>
    )
  }

  return (
    <section className="section --results" ref={resultsRef}>
      {breakpoints.m && (
        <>
          <header>
            <img
              src={BackgroundOneMobile}
              alt="Woman smiling staring through window blinds"
            />
            <div className="section-subheading">
              <Border />
              <div>
                <h2 className="heading-top ">Breakthrough</h2>
                <h2 className="heading-bottom">Finding #2</h2>
              </div>
            </div>
          </header>
          <div className="result-content">{renderResultOne()}</div>
          <header>
            <img
              src={BackgroundTwoMobile}
              alt="Woman working on a laptop while sitting on the floor"
            />
            <div className="section-subheading">
              <Border />
              <div>
                <h2 className="heading-top ">Breakthrough</h2>
                <h2 className="heading-bottom">Finding #3</h2>
              </div>
            </div>
          </header>
          <div className="result-content">{renderResultTwo()}</div>
          <header>
            <img
              src={BackgroundThreeMobile}
              alt="Audience attending a live event"
            />
            <div className="section-subheading">
              <Border />
              <div>
                <h2 className="heading-top ">Breakthrough</h2>
                <h2 className="heading-bottom">Finding #4</h2>
              </div>
            </div>
          </header>
          <div className="result-content --last">
            <ResultThree />
          </div>
        </>
      )}
      <div hidden={breakpoints.m}>
        <SectionResult image={BackgroundOne} position="first">
          {renderResultOne()}
        </SectionResult>
        <SectionResult image={BackgroundTwo} position="second">
          {renderResultTwo()}
        </SectionResult>
        <SectionResult image={BackgroundThree} position="last" isLast>
          <ResultThree />
        </SectionResult>
      </div>
    </section>
  )
}

SectionResults.propTypes = {
  onViewChange: PropTypes.func,
}

export default SectionResults
