import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { InView } from 'react-intersection-observer'

import Paragraph from 'components/Paragraph'
import Border from 'components/Border'

const Profile = ({ imageMobile, image, name, event, text, quote }) => {
  return (
    <section className="section-profile">
      <div className="image-container">
        <InView>
          {({ inView, ref }) => (
            <picture className={cx({ 'is-active': inView })} ref={ref}>
              <source media="(max-width: 991px)" srcSet={imageMobile} />
              <source media="(min-width: 992px)" srcSet={image} />
              <img src={image} alt={name} />
            </picture>
          )}
        </InView>
      </div>
      <div className="profile-container">
        <h3>{name}</h3>
        <div className="subheading">
          <Paragraph text={event} />
        </div>
        <div className="body-paragraph">
          <Paragraph text={text} />
        </div>
        <div className="blockquote-container">
          <Border />
          <blockquote>{quote}</blockquote>
        </div>
      </div>
    </section>
  )
}

Profile.propTypes = {
  imageMobile: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  event: PropTypes.string,
  text: PropTypes.string,
  quote: PropTypes.string,
}

export default Profile
