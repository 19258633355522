import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const NavButton = ({
  currentIndex,
  index,
  text,
  section,
  onScrollToSection,
  isWhite,
}) => {
  function onClick() {
    onScrollToSection(section)
    onScrollToSection(section)
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={cx('nav-button', {
        'is-active': currentIndex === index,
        'is-white-section': isWhite,
      })}
    >
      <div className="nav-button-text">{text}</div>
      <span className="nav-button-bar"></span>
    </button>
  )
}

NavButton.propTypes = {
  onScrollToSection: PropTypes.func,
  section: PropTypes.string,
  text: PropTypes.string,
  isWhite: PropTypes.bool,
  currentIndex: PropTypes.number,
  index: PropTypes.number,
}

export default NavButton
