import React, { useState } from 'react'
import cx from 'classnames'
import InView from 'react-intersection-observer'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Border from 'components/Border'

const data = [{ name: '1', value: 1 }]

const COLORS = [{ start: 'rgba(41, 79, 155, 1)', end: 'rgba(57, 108, 213, 1)' }]

const StudySectionFive = () => {
  const breakpoints = useBreakpoint()
  const [isChartActive, setChartActive] = useState(false)

  function onShowChart(isVisible) {
    if (!breakpoints.m) {
      setChartActive(isVisible)
    }
  }

  /* eslint-disable react/no-array-index-key */
  const renderChart = () => (
    <div className={cx('section-chart', { 'is-active': isChartActive })}>
      <div className="chart">
        <ResponsiveContainer width="99%" aspect={1}>
          <PieChart>
            <defs>
              {data.map((entry, index) => (
                <linearGradient
                  key={`chart-3-${index}`}
                  id={`chart-3-${index}`}
                >
                  <stop
                    offset="20%"
                    stopColor={COLORS[index % COLORS.length].start}
                  />
                  <stop
                    offset="100%"
                    stopColor={COLORS[index % COLORS.length].end}
                  />
                </linearGradient>
              ))}
            </defs>
            <Pie
              startAngle={90}
              endAngle={-270}
              data={data}
              dataKey="value"
              innerRadius="50%"
              isAnimationActive={isChartActive}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#chart-3-${index})`} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="chart-gradient"></div>
        <div className="chart-container">
          <h2>100%</h2>
        </div>
        <div className="svg-border">
          <svg
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle-dashed"
              cx="100"
              cy="100"
              r="96"
              stroke="#FFFCF3"
              strokeWidth="2"
              fillOpacity="0"
            />
          </svg>
        </div>
      </div>
    </div>
  )
  /* eslint-enable react/no-array-index-key */

  return (
    <div className="study-sub-section --with-stats">
      {renderChart()}
      <div className="study-stats blue">
        <InView threshold="0.35" initialInView onChange={onShowChart}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={cx('study-stats-container', { 'fade-in': inView })}
            >
              <h3>Date With Destiny</h3>
              <div className="study-sub-heading">
                <p>
                  <span>100%</span> of those studied were declared to be in
                  remission 30 days later.
                </p>
              </div>
              <div className="study-details">
                <p>
                  Mirroring the structure of the study done with psilocybin, but
                  without drugs, <b>Stanford Genetics Lab</b> conducted a
                  controlled study at a <b>Tony Robbins Date with Destiny</b>{' '}
                  event. Of the participants that were clinically depressed
                  before the seminar, 30 days after the event, 100% of study
                  participants who were depressed were in remission, and their
                  average improvement was 87.2%. No drugs, and only 6 days of
                  time. Additionally, at the beginning of the study, 17% of
                  participants experienced suicidal thoughts.{' '}
                  <b>
                    One month after Date with Destiny, none of the participants
                    reported suicidal thoughts!
                  </b>
                </p>
              </div>
              <figure className="section-blockquote">
                <Border isRed />
                <div>
                  <blockquote>
                    <p>
                      “I’ve been doing research for almost 20 years and have
                      published almost 300 papers. I’ve never seen anything like
                      this. The results are absolutely incredible.”
                    </p>
                  </blockquote>
                  <figcaption>
                    <b>Dr. Jacob Wilson</b> Ph.D,{' '}
                    <cite>
                      co-author of breakthrough study on Unleash the Power
                      Within
                    </cite>
                  </figcaption>
                </div>
              </figure>
            </div>
          )}
        </InView>
      </div>
    </div>
  )
}

export default StudySectionFive
