import React, { useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import { isEmpty, isEqual } from 'lodash'
import cx from 'classnames'

const SectionResult = ({ children, image, position }) => {
  const resultRef = useRef(null)
  const imageRef = useRef(null)
  const imageContainerRef = useRef(null)
  const timelineRef = useRef(null)

  useLayoutEffect(() => {
    initSetup()
    window.addEventListener('resize', onResize, false)
  }, [])

  function onResize() {
    if (!isEmpty(timelineRef.current)) {
      timelineRef.current.scrollTrigger.refresh()
    }
  }

  function initSetup() {
    setTimeout(() => {
      timelineRef.current = gsap.timeline({
        scrollTrigger: {
          trigger: resultRef.current,
          start: `-=${window.innerHeight} top`,
          end: 'bottom bottom',
          anticipatePin: 1,
          pin: imageContainerRef.current,
          pinSpacing: false,
          scrub: true,
          invalidateOnRefresh: true,
        },
      })
      timelineRef.current
        .to(imageRef.current, {
          duration: 3.8,
          scale: 1,
        })
        .to(
          imageRef.current,
          {
            opacity: 0,
            duration: 2,
          },
          isEqual(position, 'last') ? '-=3' : '-=0.5',
        )
      timelineRef.current.scrollTrigger.refresh()
    }, 1000)
  }

  return (
    <section className="result" ref={resultRef}>
      <div
        ref={imageContainerRef}
        className={`image-background is-${position}`}
      >
        <img ref={imageRef} src={image} alt="background" />
      </div>
      <div
        className={cx('result-container', {
          'is-last': isEqual(position, 'last'),
        })}
      >
        <div className="result-content">{children}</div>
      </div>
    </section>
  )
}

SectionResult.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  position: PropTypes.string.isRequired,
}

export default SectionResult
