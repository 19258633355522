import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isEmpty } from 'lodash'

import NavButton from './NavButton'

const Sidebar = ({ index, pageRef, onScrollToSection, onViewChange }) => {
  const navRef = useRef(null)

  useEffect(() => {
    if (!isEmpty(navRef.current)) {
      document.addEventListener('scroll', onScroll)
    }
  }, [])

  function onScroll() {
    window.requestAnimationFrame(() => {
      const scrollPosition = window.scrollY + window.innerHeight
      if (scrollPosition > pageRef.current.scrollHeight) {
        onViewChange(5)
      }
    })
  }

  return (
    <div ref={navRef} className={cx('nav-bar', { 'is-hidden': index === 5 })}>
      <div className={`nav-list index-${index}`}>
        <svg viewBox="0 0 2 240" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="0" x2="1" y2="493" stroke="#FFFCF3" />
        </svg>
      </div>
      <nav
        className={cx('nav-buttons', {
          'is-white': index === 3 || index === 1,
        })}
      >
        <NavButton
          currentIndex={index}
          index={0}
          text="The Video"
          section="hero"
          onScrollToSection={onScrollToSection}
        />
        <NavButton
          currentIndex={index}
          index={1}
          isWhite
          text="Study"
          section="study"
          onScrollToSection={onScrollToSection}
        />
        <NavButton
          currentIndex={index}
          index={2}
          text="Results"
          section="results"
          onScrollToSection={onScrollToSection}
        />
        <NavButton
          currentIndex={index}
          index={3}
          isWhite
          text="Participants"
          section="participants"
          onScrollToSection={onScrollToSection}
        />
        <NavButton
          currentIndex={index}
          index={4}
          text="Researchers"
          section="research"
          onScrollToSection={onScrollToSection}
        />
      </nav>
    </div>
  )
}

Sidebar.propTypes = {
  index: PropTypes.number,
  pageRef: PropTypes.object,
  onScrollToSection: PropTypes.func,
  onViewChange: PropTypes.func,
}

export default Sidebar
