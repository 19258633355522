import React from 'react'
import InView from 'react-intersection-observer'
import cx from 'classnames'

import Border from 'components/Border'

const StudySectionTwo = () => {
  return (
    <div className="study-sub-section">
      <InView threshold="1" initialInView>
        {({ inView, ref }) => (
          <div
            ref={ref}
            className={cx('sub-section-container', { 'fade-in': inView })}
          >
            <div className="study-sub-heading --section-two">
              <div className="section-subheading">
                <Border />
                <div>
                  <h2 className="heading-top ">Breakthrough</h2>
                  <h2 className="heading-bottom">Finding #1</h2>
                </div>
              </div>
              <p>
                Date with destiny is more effective in treating depression than
                traditionally studied treatments.
              </p>
            </div>
          </div>
        )}
      </InView>
    </div>
  )
}

export default StudySectionTwo
