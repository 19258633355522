import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import cx from 'classnames'
import { InView } from 'react-intersection-observer'

import Paragraph from 'components/Paragraph'

const Researcher = ({
  image,
  imageMobile,
  name,
  job,
  logo,
  text,
  children,
}) => {
  return (
    <section className="section-researcher">
      <div className="image-container">
        <InView>
          {({ inView, ref }) => (
            <picture className={cx({ 'is-active': inView })} ref={ref}>
              <source media="(max-width: 991px)" srcSet={imageMobile} />
              <source media="(min-width: 992px)" srcSet={image} />
              <img src={image} alt={name} />
            </picture>
          )}
        </InView>
      </div>
      <div className="researcher-container">
        <h3>{name}</h3>
        <Paragraph text={job} />
        {!isEmpty(logo) ? logo : children}
        <Paragraph text={text} />
      </div>
    </section>
  )
}

Researcher.propTypes = {
  imageMobile: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  job: PropTypes.string,
  logo: PropTypes.node,
  text: PropTypes.string,
  children: PropTypes.node,
}

export default Researcher
