import React, { useState } from 'react'
import cx from 'classnames'
import InView from 'react-intersection-observer'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Border from 'components/Border'

const data = [
  { name: '1', value: 5.4 },
  { name: '2', value: 4.6 },
]

const COLORS = [
  { start: 'rgba(41, 79, 155, 1)', end: 'rgba(41, 79, 155, 1)' },
  { start: 'rgba(57, 108, 213, 1)', end: 'rgba(57, 108, 213, 1)' },
]

const StudySectionFour = () => {
  const breakpoints = useBreakpoint()
  const [isChartActive, setChartActive] = useState(false)

  function onShowChart(isVisible) {
    if (!breakpoints.m) {
      setChartActive(isVisible)
    }
  }

  /* eslint-disable react/no-array-index-key */
  const renderChart = () => (
    <div className={cx('section-chart', { 'is-active': isChartActive })}>
      <div className="chart">
        <ResponsiveContainer width="99%" aspect={1}>
          <PieChart>
            <defs>
              {data.map((entry, index) => (
                <linearGradient
                  key={`chart-2-${index}`}
                  id={`chart-2-${index}`}
                >
                  <stop
                    offset="0%"
                    stopColor={COLORS[index % COLORS.length].start}
                  />
                  <stop
                    offset="100%"
                    stopColor={COLORS[index % COLORS.length].end}
                  />
                </linearGradient>
              ))}
            </defs>
            <Pie
              startAngle={90}
              endAngle={-270}
              data={data}
              dataKey="value"
              innerRadius="50%"
              isAnimationActive={isChartActive}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#chart-2-${index})`} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="chart-container">
          <h2>54%</h2>
        </div>
        <div className="svg-border">
          <svg
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle-dashed"
              cx="100"
              cy="100"
              r="96"
              stroke="#FFFCF3"
              strokeWidth="2"
              fillOpacity="0"
            />
          </svg>
        </div>
      </div>
    </div>
  )
  /* eslint-enable react/no-array-index-key */

  return (
    <div className="study-sub-section --with-stats">
      {renderChart()}
      <div className="study-stats blue">
        <InView threshold="0.35" initialInView onChange={onShowChart}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className={cx('study-stats-container', { 'fade-in': inView })}
            >
              <h3>Psilocybin Assisted Therapy</h3>
              <div className="study-sub-heading">
                <p>
                  <span>54%</span> of those studied were declared to be
                  depression-free 30 days later.
                </p>
              </div>
              <div className="study-details">
                <p>
                  One of the greatest breakthroughs in the treatment of
                  depression up until now has been psilocybin assisted therapy.
                </p>
                <p>
                  A recent breakthrough in the treatment of depression therapy
                  from Johns Hopkins University showed that psilocybin (the
                  psychedelic ingredient in “magic” mushrooms) combined with 4
                  weeks of supportive psychotherapy produced a groundbreaking
                  result: 54% of those studied were no longer depressed 30 days
                  later.
                </p>
              </div>
              <figure className="section-blockquote">
                <Border />
                <div>
                  <blockquote>
                    <p>
                      “The magnitude of the effect we saw was about four times
                      larger than what clinical trials have shown for
                      traditional antidepressants on the market.”
                    </p>
                  </blockquote>
                  <figcaption>
                    <b>Dr. Alan Davis,</b> Ph.D,{' '}
                    <cite>
                      Assistant professor of psychology at Johns Hopkins
                    </cite>
                  </figcaption>
                </div>
              </figure>
            </div>
          )}
        </InView>
      </div>
    </div>
  )
}

export default StudySectionFour
