import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import InView from 'react-intersection-observer'
import cx from 'classnames'

import Border from 'components/Border'
import StatThreeOne from 'images/stats/stat-three-one.svg'
import StatThreeTwo from 'images/stats/stat-three-two.svg'
import StatThreeThree from 'images/stats/stat-three-three.svg'

const ResultThree = () => {
  const breakpoints = useBreakpoint()

  return (
    <div className="section-header">
      {!breakpoints.m && (
        <div className="section-subheading">
          <Border />
          <div>
            <h2 className="heading-top ">Breakthrough</h2>
            <h2 className="heading-bottom">Finding #4</h2>
          </div>
        </div>
      )}
      <p>
        Tony Robbins event participants experienced a physiological shift that
        created a measurable change and lasting effect.
      </p>
      <InView>
        {({ inView, ref }) => (
          <div
            className={cx('results-row-three', { 'in-view': inView })}
            ref={ref}
          >
            <div className="result-three-one">
              <div className="result-three-stat">
                {!breakpoints.m && <StatThreeOne />}
                <div className="stat-info three-one">
                  <p className="result-three-stat-percentage">300%</p>
                  <p>
                    <b>Increase in cognitive performance </b>
                  </p>
                </div>
              </div>

              <div className="stat-details">
                <span></span>
                <p>
                  <b>300%</b> improvement in the ability to cognitively retrain
                  beliefs and attitudes, reprioritize need states, and increase
                  intrinsic motivation and fulfillment—a result sustained 30
                  days later!
                </p>
              </div>
            </div>
            <div className="result-three-two">
              <div className="result-three-stat">
                {!breakpoints.m && <StatThreeTwo />}
                <div className="stat-info three-two">
                  <p className="result-three-stat-percentage">159%</p>
                  <p>
                    <b>Increase in learning hormones, with 100% retention</b>
                  </p>
                </div>
              </div>
              <div className="stat-details">
                <span></span>
                <p>
                  <b>159%</b> increase in hormones known to boost learning—with{' '}
                  <b>100%</b> retention 30 days later!
                </p>
              </div>
            </div>
            <div className="result-three-three">
              <div className="result-three-stat">
                {!breakpoints.m && <StatThreeThree />}
                <div className="stat-info three-three">
                  <p className="result-three-stat-percentage">139%</p>
                  <p>
                    <b>Increase in performance biochemistry</b>
                  </p>
                </div>
              </div>
              <div className="stat-details">
                <span></span>
                <p>
                  <b>139%</b> Increase in testosterone to cortisol ratio – known
                  as an index of readiness to perform and is reflective of a
                  signature biochemical marker of achievement.
                </p>
              </div>
            </div>
          </div>
        )}
      </InView>
      <div className="results-row result-three">
        <figure className="result-three-figure">
          <p>
            <i>
              Findings showed that Tony’s events create unique physiological,
              biochemical, and psychological effects that create transformative
              lasting results.
            </i>
          </p>
        </figure>
      </div>
    </div>
  )
}

export default ResultThree
