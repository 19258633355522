import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import TonyRobbinsLogo from 'images/tony-robbins-logo.svg'

const StickyHeader = () => {
  const [isActive, setActive] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false)
  }, [])

  function onScroll() {
    if (window.scrollY > window.innerHeight / 2 + 100) {
      setActive(true)
    } else {
      setActive(false)
    }
  }

  return (
    <header
      className={cx('sticky-header', {
        'is-active': isActive,
      })}
    >
      <a href="/" aria-label="Return Home">
        <TonyRobbinsLogo />
      </a>
    </header>
  )
}

export default StickyHeader
