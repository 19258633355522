import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isEmpty } from 'lodash'

import SectionHeading from 'components/SectionHeading'
import Profile from 'components/Profile'

import ImageOne from 'images/profiles/profile-one.png'
import ImageTwo from 'images/profiles/profile-two.png'
import ImageThree from 'images/profiles/profile-three.png'
import ImageOneMobile from 'images/profiles/profile-one-mobile.jpg'
import ImageTwoMobile from 'images/profiles/profile-two-mobile.jpg'
import ImageThreeMobile from 'images/profiles/profile-three-mobile.jpg'

const SectionProfilees = ({ onViewChange }) => {
  const profileRef = useRef(null)

  useEffect(() => {
    if (!isEmpty(profileRef.current)) {
      setTimeout(() => {
        ScrollTrigger.create({
          onEnter: () => onViewChange(3),
          onEnterBack: () => onViewChange(3),
          onLeaveBack: () => onViewChange(3),
          trigger: profileRef.current,
          start: 'top center',
          end: `+=${profileRef.current.offsetHeight}`,
          // markers: { startColor: 'red', endColor: 'red' },
        })
      }, 4000)
    }
  }, [profileRef.current])

  return (
    <div>
      <section className="section --profilees" ref={profileRef}>
        <div className="section-wrapper">
          <div className="section-header">
            <SectionHeading top="experience" bottom="Their Story" />
          </div>
          <div className="section-body">
            <Profile
              image={ImageOne}
              imageMobile={ImageOneMobile}
              name="Yamile McBride"
              event="Attended Unleash the Power Within"
              quote="“LITERALLY during UPW I realized if I continued that behavior, in 20 years I would have diabetes, my legs would be cut off and my husband would probably be DEAD.”"
              text="My husband and I have also been able to transform our toxic relationship—we were binge drinking,  depressed, and we hated each other.  LITERALLY during UPW I realized if I continued that behavior, in 20 years I would have diabetes, my legs would be cut off and my husband would probably be DEAD. We changed IN THAT MOMENT.  Now, our relationship is strong, we have a gorgeous baby girl and I took my business to a whole new level! "
            />
            <Profile
              image={ImageTwo}
              imageMobile={ImageTwoMobile}
              name="Robert Baringer"
              event="Attended Date with Destiny"
              quote="“I built complete faith, ultimate certainty and genuine accountability at Date with Destiny.”"
              text="I built complete faith, ultimate certainty and genuine accountability at Date with Destiny, which helped me become the top government contractor in an industry of 7,000 companies. Then, we were able to really take our contribution to the next level when the VA offered us $125 million to help heal veterans and I love that. As if that wasn’t enough, the governor of Arkansas then offered us a $500 million facility to train veterans in. So that’s $750 million towards our balance sheet in the last one year. And I look forward to paying forward this great gift that you’ve given to me! Thank you Tony!"
            />
            <Profile
              image={ImageThree}
              imageMobile={ImageThreeMobile}
              name="Rosemary German"
              event="Attended Unleash the Power Within"
              quote="“When I stopped focusing on myself and really stepped into my parents’ world with love and compassion, our relationship flourished.”"
              text="For a long time, I suffered feeling like a bad daughter, and I blamed my parents for our disconnection. Until I went to UPW, and Tony taught me that the root of all my suffering was in my focus. When I stopped focusing on myself and really stepped into my parents’ world with love and compassion, our relationship flourished. Thank you Tony Robbins for the amazing gift you gave to my family."
            />
          </div>
        </div>
      </section>
    </div>
  )
}

SectionProfilees.propTypes = {
  onViewChange: PropTypes.func,
}

export default SectionProfilees
