import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isEmpty } from 'lodash'

import StudySectionOne from './StudySectionOne'
import StudySectionTwo from './StudySectionTwo'
import StudySectionThree from './StudySectionThree'
import StudySectionFive from './StudySectionFive'
import StudySectionFour from './StudySectionFour'
import StudyAnimation from './StudyAnimation'

function SectionStudy({ onViewChange }) {
  const studyRef = useRef(null)

  useEffect(() => {
    if (!isEmpty(studyRef.current)) {
      ScrollTrigger.create({
        onEnter: () => onViewChange(1),
        onEnterBack: () => onViewChange(1),
        trigger: studyRef.current,
        start: 'top center',
        end: 'bottom center',
        // markers: { startColor: 'yellow', endColor: 'yellow' },
      })
    }
  }, [studyRef.current])

  /* eslint-disable react/no-array-index-key */
  return (
    <section ref={studyRef} className="section --study">
      <div className="section-wrapper">
        <StudyAnimation name="one">
          <StudySectionOne />
        </StudyAnimation>

        <StudyAnimation name="two">
          <StudySectionTwo />
        </StudyAnimation>

        <StudyAnimation name="three">
          <StudySectionThree />
        </StudyAnimation>

        <StudyAnimation name="four">
          <StudySectionFour />
        </StudyAnimation>

        <StudyAnimation name="five">
          <StudySectionFive />
        </StudyAnimation>
      </div>
    </section>
  )
  /* eslint-enable react/no-array-index-key */
}

SectionStudy.propTypes = {
  onViewChange: PropTypes.func,
}

export default SectionStudy
