import React from 'react'
import InView from 'react-intersection-observer'
import cx from 'classnames'

const StudySectionOne = () => {
  return (
    <div className="study-sub-section">
      <InView threshold="1" initialInView>
        {({ inView, ref }) => (
          <div
            ref={ref}
            className={cx('sub-section-container', { 'fade-in': inView })}
          >
            <div className="section-heading">
              <h2 className="heading-top --logo">
                <div className="heading-top-logo">
                  <span>Study Results</span>
                </div>
              </h2>
              <h2 className="heading-bottom">
                THE SCIENTIFIC IMPACT OF TONY ROBBINS EVENTS
              </h2>
            </div>
          </div>
        )}
      </InView>
    </div>
  )
}

export default StudySectionOne
