import React from 'react'
import PropTypes from 'prop-types'

const StudyAnimation = ({ children }) => {
  return <div className="study-animation">{children}</div>
}

StudyAnimation.propTypes = {
  children: PropTypes.element,
}

export default StudyAnimation
